import { Box, TextInput, Title, Tooltip } from "@mantine/core";
import { Fragment } from "react/jsx-runtime";
// import InsuranceList from "./insurance/InsuranceList";
// import QRScannerComponent from "./QRScanner";
import { IconChevronLeft } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import OrderPatientList from "./OrderPatientList";
import "./styles.scss";

const PatientOrderInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: patientData } = location.state;

  // const handleSubmit = (value: string) => {
  //     setQrScanner(state => ({
  //         ...state,
  //         isOpen: false,
  //     }));
  //     setTrigger(0);
  //     navigate(routes.getDeliveryDispensation, {
  //         state: { orderId: value },
  //     });
  // };

  const searchSection = () => {
    return (
      <>
        <div className="searchSection">
          <Box>
            <Box px={"lg"} pt={"xl"} mt={"sm"} pb={"xs"} className="">
              <div className="flex items-center pointer" onClick={() => navigate(-1)}>
                <Tooltip label="Regresar" withArrow position="right">
                  <IconChevronLeft style={{ height: 30, width: 30 }} />
                </Tooltip>
              </div>
              <Title size={"h3"} py={"xs"} c="secondary-color" fw={"bold"} ta={"center"}>
                Buscar ordenamiento
              </Title>
            </Box>
            <Box px={"lg"}>
              <div className="">
                <TextInput
                  label="Identificación del paciente"
                  placeholder="Escribir..."
                  value={`${patientData.docTypeDesc} ${patientData.document}`}
                  disabled
                />
                <TextInput
                  label="Primer Nombre"
                  placeholder="Escribir..."
                  value={patientData.first_name}
                  disabled
                />
                <TextInput
                  label="Primer apellido"
                  value={patientData.last_name}
                  placeholder="Seleccionar..."
                  disabled
                />
                <TextInput
                  label="Fecha de nacimiento"
                  type="date"
                  placeholder="Escribir..."
                  value={patientData.birth_date}
                  disabled
                />
                <TextInput
                  label="Whatsapp"
                  value={patientData.whatsapp}
                  placeholder="Escribir..."
                  disabled
                />
                <TextInput
                  label="Correo"
                  value={patientData.email}
                  placeholder="Escribir..."
                  disabled
                />
              </div>
            </Box>
          </Box>
        </div>
      </>
    );
  };

  const renderComponent = () => {
    return (
      <Fragment>
        <div className="bodyOrder">
          {searchSection()}

          <div className="renderSection">
            <OrderPatientList />
          </div>
        </div>
      </Fragment>
    );
  };

  return renderComponent();
};

export default PatientOrderInformation;

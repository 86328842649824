import { warningNotification } from "@app/common/components/notifications";
import { GCidAccount } from "@app/config/globalsConst";
import routes from "@app/config/routes";
import { useGetCompanyTypeList } from "@app/services/common/hooks";
import { Card, Center, Flex, Group, Text, Title } from "@mantine/core";
import { IconMedicineSyrup } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import "./styles.scss";

export default function SelectionDelivery() {
  const navigate = useNavigate();
  const goTo = (prefix: string) => {
    if (prefix === "eps") {
      navigate(routes.getEpsOrder);
    } else if (prefix === "particular") {
      navigate(routes.particularRoute);
    } else {
      warningNotification("En desarrollo, prueba con EPS, o PARTICULAR");
    }
  };

  const {
    data: companyType = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyTypeList({
    eaccount: GCidAccount(),
  });

  const renderComponent = () => {
    return (
      <Fragment>
        <Center w={"100%"} h={"100vh"}>
          <Flex mih={50} gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Title mb="sm" size={"h2"} c="secondary-color" fw={"bold"}>
              Entrega de medicamentos
            </Title>
            <Group px="xl" justify="space-around">
              {companyType.results.length > 0 &&
                companyType.results.map(company => {
                  return (
                    <Card
                      key={company.id}
                      shadow="sm"
                      padding="lg"
                      radius="md"
                      __size="xl"
                      w={250}
                      onClick={() => {
                        goTo(company.tag);
                      }}
                      className="card"
                    >
                      <div className="flex-column justify-center items-center cardSpace">
                        <div className="pinColor">
                          <IconMedicineSyrup
                            style={{
                              width: 30,
                              height: 30,
                            }}
                            className="primary-color"
                          />
                        </div>
                        <Text size="sm" mt={"sm"} fw="bold" className="primary-color">
                          {company.name}
                        </Text>
                      </div>
                    </Card>
                  );
                })}
            </Group>
          </Flex>
        </Center>
      </Fragment>
    );
  };

  return renderComponent();
}

import { Box, Button, Modal, Table, TextInput } from "@mantine/core";

import { useGetLotsScanner } from "@app/services/drugDelivery/hooks";
import { Lot, LotsScannerResponse } from "@app/services/drugDelivery/types";
import { useDebouncedState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import "./styles.scss";

export default function MedicationScanner({
  isOpen,
  onClose,
  orderId,
  handleChange,
}: {
  orderId: number;
  isOpen: boolean;
  handleChange: (data: Lot[]) => void;
  onClose: () => void;
}) {
  const [barcode, setBarcode] = useDebouncedState<string | null>(null, 500);
  const [lotList, setLotList] = useState<LotsScannerResponse>({
    lots: [],
    id: "",
    barcode: "",
    description: "",
    manufacturer: "",
    salesPrice: "",
    medicationOrderId: "",
    OrderId: "",
    medicineId: "",
    medicineCUM: "",
    medicineInvima: "",
  });

  const {
    data: lotListRender = {
      results: {
        lots: [],
        id: "",
        barcode: "",
        description: "",
        manufacturer: "",
        salesPrice: "",
        medicationOrderId: "",
        OrderId: "",
        medicineId: "",
        medicineCUM: "",
        medicineInvima: "",
      },
      success: false,
    },
  } = useGetLotsScanner(
    {
      barcode: Number(barcode),
      orderId,
    },
    barcode !== null,
  );
  useEffect(() => {
    if (lotListRender && lotListRender?.results && lotListRender?.success) {
      setLotList(lotListRender.results);
    }
  }, [lotListRender.results, lotListRender.success]);

  const handleChangeQtyLots = (value: string, option: Lot, medicineId: number | string) => {
    const resLot = lotList?.lots?.map(x =>
      x.id === option.id ? { ...x, qtyUsed: Number(value), medicineId } : x,
    );
    setLotList(state => ({ ...state, lots: resLot }));
    if (resLot) {
      handleChange(resLot);
    }
  };

  const renderRow = (option: Lot) => {
    const date = (option?.expirationDate ?? "").split("T")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={`${option?.id}`}>
        <Table.Td>{lotList?.manufacturer}</Table.Td>
        <Table.Td>{lotList?.medicineCUM}</Table.Td>
        <Table.Td>{lotList?.medicineInvima}</Table.Td>
        <Table.Td>{option?.warehouseId}</Table.Td>
        <Table.Td ta={"center"}>{date}</Table.Td>
        <Table.Td ta={"center"}>{option?.qtyAvailable}</Table.Td>
        <Table.Td ta={"center"}>{lotList?.salesPrice}</Table.Td>
        <Table.Td ta={"center"} className="flex justify-center">
          <TextInput
            w={"50%"}
            type="number"
            key={option.id}
            data-testid={option.id}
            placeholder="Escribir..."
            defaultValue={option.qtyUsed}
            onChange={({ target }) => {
              handleChangeQtyLots(target.value, option, lotList.medicineId);
            }}
          />
        </Table.Td>
        <Table.Td ta={"start"}>{option.lotName}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table" withTableBorder key={lotList?.lots?.length}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"} bg={"#fff"}>
              Lab
            </Table.Th>
            <Table.Th bg={"#fff"}>CUM</Table.Th>
            <Table.Th bg={"#fff"}>Invima</Table.Th>
            <Table.Th bg={"#fff"} ta={"start"}>
              Lote
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Vencimiento
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Stock
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Costo
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Cantidad
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"start"}>
              Descripción
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {lotList?.lots?.length ? (
            lotList?.lots.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const closeModal = () => {
    onClose();
    setBarcode(null);
    setLotList({
      lots: [],
      id: "",
      barcode: "",
      description: "",
      manufacturer: "",
      salesPrice: "",
      medicationOrderId: "",
      OrderId: "",
      medicineId: "",
      medicineCUM: "",
      medicineInvima: "",
    });
  };

  return (
    <Modal w="45%" size={1000} title="Escanear medicamentos" opened={isOpen} onClose={closeModal}>
      <Box className="flex flex-column justify-center">
        <TextInput
          placeholder="Escribir..."
          label="Ingresar código"
          autoFocus
          w={"30%"}
          data-testid="medicineCode"
          // value={searchV}
          onChange={({ target }) => {
            setBarcode(target.value);
          }}
        />
      </Box>
      <Box py={"sm"}>{renderTable()}</Box>
      <Box className="flex justify-end gap-3">
        <Button variant="outline" onClick={closeModal}>
          Cancelar
        </Button>
        {/* <Button variant="filled">Guardar</Button> */}
      </Box>
    </Modal>
  );
}

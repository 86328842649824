import * as MC from "@mantine/core";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { Functionality } from "@app/services/category/types";
import { getEnvs } from "../utils/getEnvs";

import "./styles.scss";

interface ISidebarLinkProps {
  label: string;
  links: Functionality[];
}

const env = getEnvs();

export default function HomeLink(props: ISidebarLinkProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onClickLink = () => {
    setIsOpen(state => !state);
  };

  const onNavigateExternalUrl = (url: string) => {
    window.location.href = `${env.URL_PROTOCOL}${env.URL_BASE}${url}`;
  };

  const renderSubLinks = () => {
    return props.links.map(elem => (
      <Fragment key={elem.id}>
        {elem.url.includes("/dispensation") ? (
          <Link to={elem.url} className="home-link">
            {elem.name}
          </Link>
        ) : (
          <div className="home-link" onClick={() => onNavigateExternalUrl(elem.url)}>
            {elem.name}
          </div>
        )}
      </Fragment>
    ));
  };

  const renderComponent = () => {
    return (
      <>
        <MC.UnstyledButton className="category-item" onClick={onClickLink}>
          <MC.Title py="xs" order={5} c="gray.7">
            {props.label.replace("TM", "Tabla maestra")}
          </MC.Title>
        </MC.UnstyledButton>
        {props.links.length > 0 ? (
          <MC.Collapse in={isOpen} style={{ whiteSpace: "nowrap" }} mb="md">
            {renderSubLinks()}
          </MC.Collapse>
        ) : null}
      </>
    );
  };

  return renderComponent();
}

import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { useEffect, useState } from "react";

/**
 * Check if all running requests are completed or not.
 * @returns {boolean}
 */
export const useLoading = (): boolean => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isFetching || isMutating) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFetching, isMutating]);

  return isLoading;
};

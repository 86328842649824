/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { successNotification, warningNotification } from "@app/common/components/notifications";
import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createSupportService,
  supportParamsListService,
  updateSupportService,
} from "./supportParams";
import { SupportParamsFilter, SupportParamsPayload, SupportUpdatePayload } from "./types";

export function useGetSupportParamsList(
  filters: SupportParamsFilter & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["supportParams", filters.corporateClient, filters.contractId, filters.trigger],
    queryFn: async () => await supportParamsListService(filters, token),
  });

  return query;
}
export function useCreateSupport(onSuccess = async () => {}) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: SupportParamsPayload) => await createSupportService(payload, token),
    onSuccess: async res => {
      successNotification(res.message, "Realizado con éxito");
      await onSuccess();
    },
    onError: async (error: any) => {
      warningNotification(error.response.data.error, "Intenta de nuevo");
    },
  });

  return mutation;
}
export function useUpdateSupport(onSuccess = async () => {}) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: SupportUpdatePayload) => await updateSupportService(payload, token),
    onSuccess: async res => {
      successNotification(res.message, "Realizado con éxito");
      await onSuccess();
    },
    onError: async (error: any) => {
      warningNotification(error.response.data.error, "Intenta de nuevo");
    },
  });

  return mutation;
}

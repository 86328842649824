import { useQuery } from "@tanstack/react-query";

import { HTTP_SECURITY } from "@app/config/axios";

import type { CategoriesResponse, GetCategoriesFilters } from "./types";

import { useAuthentication } from "@app/store/useAuthtentication";

async function fetchCategories(filters: GetCategoriesFilters, token: string) {
  const response = await HTTP_SECURITY(token).get<CategoriesResponse>("/permission/category/", {
    params: filters,
  });

  return response.data;
}

export function useGetCategories(filters: GetCategoriesFilters) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    queryKey: ["categories"],
    queryFn: async () => await fetchCategories(filters, token),
  });

  return query;
}

import { warningNotification } from "@app/common/components/notifications";
import { Modal, ScrollArea } from "@mantine/core";
import QRScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";

export default function QRScannerComponent({
  handleSubmit,
  onClose,
  open,
  setQrScanner,
  trigger,
}: {
  onClose: () => void;
  open: boolean;
  handleSubmit: (value: string) => void;
  setQrScanner: (value: number) => void;
  trigger: number;
}) {
  const [scanner, setScanner] = useState<QRScanner | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const extractOrderNumber = (text: string) => {
    const match = text.match(/Orden N°:\s*(\d+)/);
    return match ? match[1] : "";
  };

  const initializeScanner = async () => {
    if (videoRef.current) {
      const newScanner = new QRScanner(
        videoRef.current,
        result => {
          const orderNumber = extractOrderNumber(String(result.data));
          if (orderNumber) {
            handleSubmit(orderNumber);
            newScanner.stop();
          } else {
            warningNotification(
              "El código QR no contiene un número de orden, verifique que este pertenezca a un ordenamiento",
              "intenta de nuevo",
            );
          }
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        },
      );

      setScanner(newScanner);

      try {
        await newScanner.start();
      } catch (error) {
        console.error("Error al iniciar el QRScanner:", error);
      }
    } else {
      console.warn("videoRef.current es null. Reintentando inicialización...");
      setTimeout(initializeScanner, 100); // Reintentar tras 100ms
    }
  };

  useEffect(() => {
    if (open) {
      if (trigger === 0) {
        setQrScanner(trigger + 1);
      }
      initializeScanner();
    } else if (scanner) {
      scanner.stop();
      setScanner(null);
    }

    return () => {
      if (scanner) {
        scanner.stop();
      }
    };
  }, [open, videoRef.current, trigger]);

  return (
    <Modal
      size={500}
      title="Escáner de QR"
      opened={open}
      onClose={onClose}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <div className="flex justify-center items-center" key={trigger}>
        <video
          data-testid="videoScanner"
          ref={videoRef}
          style={{ width: "100%", height: "auto" }}
          autoPlay
          playsInline
        />
      </div>
    </Modal>
  );
}

import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import { DeliveryAuthFilters, DeliveryAuthResponse, RequestFullDeliveryPayload } from "./types";

export async function listDeliveryAuthListService(
  filters: DeliveryAuthFilters & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<DeliveryAuthResponse[]>>(
    "/requestCompleteDelivery/",
    { params: filters },
  );
  return res.data;
}

export async function medicationOrderService(filters: { id: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<{ results: string }>(
    `/ordering/pdfMedicationOrder/${filters.id}`,
  );
  return res.data;
}

export async function handleAuthDelivery(
  payload: { rcdRejectReason?: string; approved: 0 | 1; id: number },
  token: string,
) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.patch<IResponse>(
    `/requestCompleteDelivery/${payload.id}`,
    payload,
  );
  return response.data;
}

export async function requestDeliveryService(payload: RequestFullDeliveryPayload, token: string) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.post<IResponse>(`/requestCompleteDelivery/`, payload);
  return response.data;
}

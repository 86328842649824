import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { getEnvs } from "@app/common/utils/getEnvs";
import routes from "@app/config/routes";
import { useAuthentication } from "@app/store/useAuthtentication";

const env = getEnvs();

export default function Guardian() {
  const location = useLocation();
  const auth = useAuthentication(state => state.auth.dataBiowel);

  useEffect(() => {
    if (!auth.token) {
      window.location.href = `${env.URL_PROTOCOL}${env.URL_BASE}/categoryselect`;
    }
  }, [auth.token]);

  const functionalityUrl = location.state;

  return (
    auth.token &&
    (functionalityUrl ? <Navigate to={functionalityUrl} /> : <Navigate to={routes.dispensation} />)
  );
}

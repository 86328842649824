// eslint-disable-next-line prettier/prettier
export interface IResponse<T = unknown> {
  data?: T;
  results: T;
  status: number;
  success: boolean;
  message: string;
  rowTotal: number;
  title: null;
  row_total?: number;
}

export interface IFiltersPagination {
  page?: number;
  perpage?: number;
  search?: string;
}

export enum IHttpStatusCodes {
  Success = 200,
  Created = 201,
  BadRequest = 400,
  Unauthorized = 401,
}

export interface commonFilters {
  eaccount: number;
  status?: string | number;
}

import { getEnvs } from "@app/common/utils/getEnvs";
import axios from "axios";

const getBaseURL = (url: string, token: string) => {
  return {
    baseURL: url,
    headers: {
      Accept: "application/json",
      Authorization: token,
    },
  };
};

const { URL_GATEWAY, API_VERSION, URL_BASE, URL_PROTOCOL } = getEnvs();

export const HTTP_BASE = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_SURGERY = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/surgery`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_SURGERIES = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/surgery/surgeries`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_SECURITY = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/security`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_MEDICAL = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/medical`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_PAYROLL = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/payroll`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_ADMIN = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/admin`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_INVENTORY = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/inventory`;
  return axios.create(getBaseURL(url, token));
};

export const HTTP_INVOICING = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/invoicing`;
  return axios.create(getBaseURL(url, token));
};
export const HTTP_DISPENSARY = (token: string) => {
  const url = `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/dispensaryms`;
  return axios.create(getBaseURL(url, token));
};

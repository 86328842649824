import routes from "@app/config/routes";
import { useGetOrdersList } from "@app/services/drugDelivery/hooks";
import { Medicine } from "@app/services/drugDelivery/types";
import { Box, Card, Grid, Table, Title, Tooltip } from "@mantine/core";
import {
  IconAlertCircle,
  IconChevronLeft,
  IconCircleCheck,
  IconCircleX,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";

const OrderPatientList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: ordersList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetOrdersList({
    type: "patient",
    patient: `${location.state.data.document}`,
  });

  const renderRow = (option: Medicine) => {
    return (
      <Table.Tr key={option?.id}>
        <Table.Td>{option?.id}</Table.Td>
        <Table.Td>{option?.name}</Table.Td>

        <Table.Td ta={"center"}>{option?.qtyOrder ?? "-"} unds.</Table.Td>
        <Table.Td ta={"center"}>{option?.qtyDelivered ?? "-"} unds.</Table.Td>
        <Table.Td ta={"center"}>{option?.qtyPending ?? "-"} unds.</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = (medicines: Medicine[]) => {
    return (
      <Table className="biowel-table" withTableBorder verticalSpacing={"0.3rem"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th bg={"#fff"} ta={"start"}>
              Código
            </Table.Th>
            <Table.Th bg={"#fff"}>Nombre</Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Cantidad ordenada
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Cantidad entregada
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Cantidad pendiente
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {medicines ? (
            medicines?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const renderCard = (option: (typeof ordersList.results)[number]) => {
    return (
      <Card
        key={option.orderId}
        mb={"xs"}
        data-testid="medicationOrder"
        p={0}
        onClick={() =>
          navigate(routes.getDeliveryDispensation, {
            state: { orderId: option?.orderId },
          })
        }
      >
        <Box
          bg={option.rcdInfo?.status === "Pendiente" ? "rgba(253, 126, 20, 0.05)" : ""}
          py={"sm"}
          px={"lg"}
        >
          <Box py={"xs"} className="flex justify-between">
            {" "}
            <Title size={"h5"} py={0} className="secondary-color">
              <b>Fórmula de medicamentos</b>
            </Title>
            {Object.keys(option?.rcdInfo ?? {}).length > 0 && (
              <Card py={0} className="flex items-center justify-center">
                {" "}
                <div className="flex gap-2">
                  Solicitud de entrega total: <b>{option.rcdInfo?.status}</b>-{" "}
                  {option.rcdInfo?.reviewedAt?.split(" ")[0]}{" "}
                  <Tooltip
                    label={
                      option.rcdInfo?.status === "Rechazada"
                        ? `Solicitud ${option.rcdInfo?.status}: ${option?.rcdInfo?.rcdRejectReason}`
                        : `Solicitud ${option.rcdInfo?.status}`
                    }
                  >
                    <span>
                      {option.rcdInfo?.status === "Aprobado" ? (
                        <IconCircleCheck color="#7bc62d" />
                      ) : option.rcdInfo?.status === "Pendiente" ? (
                        <IconAlertCircle color="#ff4500" />
                      ) : (
                        <IconCircleX color="#ff2825" />
                      )}
                    </span>
                  </Tooltip>
                </div>
              </Card>
            )}
          </Box>
          <Grid py={"0.2rem"}>
            <Grid.Col span={3}>
              <b>Aseguradora</b>
            </Grid.Col>
            <Grid.Col span={9}>{option?.corporateClient.name}</Grid.Col>
          </Grid>
          <Grid py={"0.2rem"}>
            <Grid.Col span={3}>
              <b>Fecha de orden</b>
            </Grid.Col>
            <Grid.Col span={9}>{option?.createdAtMedicalOrders}</Grid.Col>
          </Grid>
          <Grid py={"0.2rem"}>
            <Grid.Col span={3}>
              <b>Ordenado por</b>
            </Grid.Col>
            <Grid.Col span={9}>{option?.doctor}</Grid.Col>
          </Grid>
          <Grid py={"0.2rem"}>
            <Grid.Col span={3}>
              <b>Validez</b>
            </Grid.Col>
            <Grid.Col span={9}>{option?.validUntil}</Grid.Col>
          </Grid>
          <Grid py={"0.2rem"}>
            <Grid.Col span={3}>
              <b>Observaciones</b>
            </Grid.Col>
            <Grid.Col span={9}>{option?.observations}</Grid.Col>
          </Grid>
          <Box py={"sm"}>{renderTable(option.medicines)}</Box>
        </Box>
      </Card>
    );
  };

  return (
    <div className="main-container" data-testid={"order-patient-list"}>
      <Box className="flex gap-2">
        <div
          data-testid="goback"
          className="flex items-center pointer"
          onClick={() => navigate(-1)}
        >
          <Tooltip label="Regresar" withArrow position="right">
            <IconChevronLeft style={{ height: 30, width: 30 }} className="primary-color pointer" />
          </Tooltip>
        </div>
        <Title size={"h3"} py={"xs"} c="secondary-color" fw={"bold"} ta={"center"}>
          Ordenamientos
        </Title>
      </Box>
      <Box w={"100%"}>
        {ordersList?.results?.length ? (
          ordersList?.results?.map(renderCard)
        ) : (
          <Box>No hay ordenamientos pendientes.</Box>
        )}{" "}
      </Box>
    </div>
  );
};

export default OrderPatientList;

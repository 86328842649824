import SignatureComponent from "@app/common/components/SignatureComponent";
import { Template } from "@app/common/components/Template";
import routes from "@app/config/routes";
import { useGetOrderDetail } from "@app/services/drugDelivery/hooks";
import {
  FileAdditionalSupports,
  Lot,
  Medicine,
  OrderListResponse,
} from "@app/services/drugDelivery/types";
import { calculateAge } from "@app/utils/generateId";
import { Box, Button, Card, Grid, Table, Text, TextInput, Tooltip } from "@mantine/core";
import { IconCamera, IconEye, IconUpload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeliveryRegisterMultiPicture from "./DeliveryRegisterMultiPicture";
import DeliveryRegisterPicture from "./DeliveryRegisterPicture";
import DeliverySupportModal from "./DeliverySupportModal";
import FullDelivery from "./FullDelivery";
import MedicationScanner from "./MedicationScanner";
import "./styles.scss";

const Delivery = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [orderId, setOrderId] = useState(location?.state?.orderId);
  const [data, setData] = useState<OrderListResponse>();
  const [FullDeliveryDrawer, setFullDeliveryDrawer] = useState({
    isOpen: false,
  });
  const [supportFilesModal, setSupportFilesModal] = useState<{
    isOpen: boolean;
    data: FileAdditionalSupports[];
  }>({
    isOpen: false,
    data: [],
  });

  const [imagesModal, setImagesModal] = useState<{
    isOpenFaceModal: boolean;
    isOpenDocumentModal: boolean;
  }>({
    isOpenFaceModal: false,
    isOpenDocumentModal: false,
  });

  const [lotsMedication, setLotsMedication] = useState<{
    isOpen: boolean;
    data: { qtyUsed: number; id: number; medicineId: number }[];
  }>({
    isOpen: false,
    data: [],
  });
  const { data: orderDetail } = useGetOrderDetail({
    orderId,
  });

  const renderRow = (option: Medicine) => {
    const filtered = lotsMedication.data.filter(d => d.medicineId === option.id);

    const total = filtered.reduce((acc, item) => {
      return acc + item.qtyUsed;
    }, 0);
    return (
      <Table.Tr key={option.id}>
        <Table.Td>{option.id}</Table.Td>
        <Table.Td>{option.name}</Table.Td>
        <Table.Td ta={"start"}>{option.qtyOrder}</Table.Td>
        <Table.Td ta={"start"}>{option.qtyOrder}</Table.Td>
        <Table.Td ta={"start"}>{option.qtyOrder}</Table.Td>
        <Table.Td ta={"start"}>{option.qtyOrder}</Table.Td>
        <Table.Td ta={"center"}>{total}</Table.Td>
        <Table.Td ta={"start"}>{option.qtyOrder}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table mt={"sm"} mb="sm" className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Código</Table.Th>
            <Table.Th>Medicamento</Table.Th>
            <Table.Th>Stock disp.</Table.Th>
            <Table.Th ta={"start"}>Ordenado</Table.Th>
            <Table.Th ta={"start"}>Entrega anterior</Table.Th>
            <Table.Th ta={"start"}>Estimado actual</Table.Th>
            <Table.Th ta={"center"}>Entrega real</Table.Th>
            <Table.Th ta={"start"}>Postfechado</Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {orderDetail?.results?.medicines?.length ? (
            orderDetail?.results?.medicines?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const onCloseLotsModal = () => {
    setLotsMedication(state => ({ ...state, isOpen: false }));
  };

  const handleChangeCompanion = (value: string) => {
    setData(state => ({
      ...state,
      patientSignature: value,
    }));
  };

  const handlePictureFace = (file: string) => {
    setData(state => ({
      ...state,
      patientFacePicture: file,
    }));
  };
  const handlePictureDocument = (files: string[]) => {
    setData(state => ({
      ...state,
      patientDocumentPictures: files,
    }));
  };

  const handleChangeQtyLots = (lots: Lot[]) => {
    const filtered = lots.filter(l => l.qtyUsed) ?? [];
    setLotsMedication(state => ({
      ...state,
      data: filtered.map(lot => ({
        id: lot.id,
        qtyUsed: Number(lot.qtyUsed),
        medicineId: Number(lot.medicineId),
      })),
    }));
  };

  const handleSupportFiles = (files: FileAdditionalSupports[]) => {
    setSupportFilesModal(state => ({ ...state, data: files }));
  };

  useEffect(() => {
    setOrderId(location?.state?.orderId);
  }, [location?.state?.orderId]);

  return (
    <Template
      title="Entrega de Medicamentos"
      goBackButton={() => {
        navigate(-1);
      }}
    >
      {supportFilesModal.isOpen && (
        <DeliverySupportModal
          data={supportFilesModal.data}
          contractId={Number(orderDetail?.results?.orderInfo?.contractId)}
          handleChange={handleSupportFiles}
          onClose={() => {
            setSupportFilesModal(state => ({
              ...state,
              isOpen: false,
            }));
          }}
          open={supportFilesModal.isOpen}
        />
      )}
      <FullDelivery
        dataPatient={orderDetail?.results?.orderInfo}
        onClose={() => {
          setFullDeliveryDrawer(state => ({
            ...state,
            isOpen: false,
          }));
        }}
        isOpen={FullDeliveryDrawer.isOpen}
      />
      <MedicationScanner
        orderId={location?.state?.orderId}
        isOpen={lotsMedication.isOpen}
        onClose={onCloseLotsModal}
        handleChange={handleChangeQtyLots}
      />
      <DeliveryRegisterPicture
        setData={handlePictureFace}
        data={data?.patientFacePicture}
        isOpen={imagesModal.isOpenFaceModal}
        onClose={() => {
          setImagesModal(state => ({
            ...state,
            isOpenFaceModal: false,
          }));
        }}
      />
      <DeliveryRegisterMultiPicture
        setData={handlePictureDocument}
        data={data?.patientDocumentPictures ?? []}
        isOpen={imagesModal.isOpenDocumentModal}
        onClose={() => {
          setImagesModal(state => ({
            ...state,
            isOpenDocumentModal: false,
          }));
        }}
      />
      <Box>
        <Text fz="md" ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Información del cliente
        </Text>
        <Card mb={"md"} padding="lg" radius="md" __size="xl" className="">
          <Grid>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Paciente
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {orderDetail?.results?.orderInfo?.patient?.name}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Identificación
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {orderDetail?.results?.orderInfo?.patient?.documentType}
                {orderDetail?.results?.orderInfo?.patient?.document}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Edad
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {calculateAge(orderDetail?.results?.orderInfo?.patient?.birthDate ?? "")} años
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Aseguradora
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {orderDetail?.results?.orderInfo?.corporateClient?.name}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Contrato
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {orderDetail?.results?.orderInfo?.contract}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Tipo de afiliado
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {orderDetail?.results?.orderInfo?.patient?.affiliationType}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Población
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {orderDetail?.results?.orderInfo?.population}
              </Text>
            </Grid.Col>
          </Grid>
        </Card>
        <Text fz="md" ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Medicamentos a entregar
        </Text>

        <div data-testid="GotoDetail" className="flex justify-end items-center ">
          <Tooltip label="Ver fórmula" withArrow position="right">
            <IconEye
              onClick={() =>
                navigate(routes.getDeliveryDetailOrder, {
                  state: { data: orderId },
                })
              }
              style={{ height: 30, width: 30 }}
              className="primary-color pointer"
            />
          </Tooltip>
        </div>
        {renderTable()}
        <Box className="flex justify-end gap-3">
          <Box className="flex justify-end">
            <Button
              variant="outline"
              onClick={() =>
                setFullDeliveryDrawer(state => ({
                  ...state,
                  isOpen: true,
                }))
              }
            >
              Solicitar entrega completa
            </Button>
          </Box>
          <Box className="flex justify-end">
            <Button
              variant="filled"
              onClick={() =>
                setLotsMedication(state => ({
                  ...state,
                  isOpen: true,
                }))
              }
            >
              Escanear medicamentos
            </Button>
          </Box>
        </Box>

        <Text fz="md" pt={"sm"} ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Registro de entrega
        </Text>
        <Box className="flex justify-end ">
          <Button
            variant="light"
            data-testid="openSupports"
            onClick={() => {
              setSupportFilesModal(state => ({
                ...state,
                isOpen: true,
              }));
            }}
          >
            <IconUpload /> &nbsp; <span> Adjuntar soportes</span>
          </Button>
        </Box>
        <Box className="flex gap-3" my={"xs"}>
          <Box w={"150%"}>
            <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
              Fotografía del rostro
            </Text>
            <div
              className="cardCamera"
              onClick={() => {
                setImagesModal(state => ({
                  ...state,
                  isOpenFaceModal: true,
                }));
              }}
            >
              {data?.patientFacePicture ? (
                <img
                  src={`data:application/pdf;base64,${data?.patientFacePicture}`}
                  alt="pictureFace"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2%",
                  }}
                />
              ) : (
                <Text fz="md" ml="xs" fw={"inherit"}>
                  Tomar foto
                </Text>
              )}
              <div className="cameraButton">
                <IconCamera style={{ width: 20, height: 20 }} className="primary-color pointer" />
              </div>
            </div>
          </Box>
          <Box w={"150%"}>
            <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
              Fotografía de la identificación
            </Text>
            <div
              className="cardCamera"
              onClick={() => {
                setImagesModal(state => ({
                  ...state,
                  isOpenDocumentModal: true,
                }));
              }}
            >
              <Box className="flex containerDocumentPicture">
                {data?.patientDocumentPictures && data?.patientDocumentPictures?.length > 0 ? (
                  data?.patientDocumentPictures.map(image => (
                    <img
                      width={"75%"}
                      src={`data:application/pdf;base64,${image}`}
                      alt="pictureFace"
                    />
                  ))
                ) : (
                  <Text fz="md" ml="xs" fw={"inherit"}>
                    Tomar foto
                  </Text>
                )}
                <div className="cameraButton">
                  <IconCamera style={{ width: 20, height: 20 }} className="primary-color pointer" />
                </div>
              </Box>
            </div>
          </Box>
          <Box w={"100%"}>
            <TextInput
              label="No. identificación de quien recibe"
              placeholder="Escribir..."
              onChange={({ target }) => {
                setData(state => ({
                  ...state,
                  receiveDocument: target.value,
                }));
              }}
            />
            <TextInput
              label="Nombre de quien recibe"
              placeholder="Escribir..."
              onChange={({ target }) => {
                setData(state => ({
                  ...state,
                  receiveName: target.value,
                }));
              }}
            />
            <Box className="flex flex-column" mt="sm" h={"100%"}>
              <Text fz="xs" flex={1} c="secondary-color" fw={"inherit"}>
                Firma
              </Text>{" "}
              <Box className="col-9 text-primary flex" w={"100%"} h={"100%"}>
                <SignatureComponent
                  handleChangeCompanion={handleChangeCompanion}
                  firm={data?.patientSignature}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Template>
  );
};

export default Delivery;

import { formattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import routes from "@app/config/routes";
import { useGetCompanyClientList, useGetPatientList } from "@app/services/common/hooks";
import { Box, Button, Image, Select, Text, TextInput, Title } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import toOrder from "../../../assets/toOrder.svg";
import InsuranceList from "./insurance/InsuranceList";
import QRScannerComponent from "./QRScanner";
import "./styles.scss";

const Order = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useDebouncedState("", 500);
  const [searchV, setSearchV] = useState("");
  const [typeOrder, setTypeOrder] = useState<"patient" | "corpClient">("patient");
  const [qrScanner, setQrScanner] = useState<{
    isOpen: boolean;
  }>({
    isOpen: false,
  });
  const [trigger, setTrigger] = useState(0);
  const [insuranceValue, setInsuranceValue] = useState<number | string>("");
  const {
    data: patientList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetPatientList({
    perpage: 100,
    page: 1,
    search,
  });
  const {
    data: insuranceList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyClientList({
    contractCompanyType: "eps",
    status: "enabled",
    eaccount: GCidAccount(),
  });

  const handleSubmit = (value: string) => {
    setQrScanner(state => ({
      ...state,
      isOpen: false,
    }));
    setTrigger(0);
    navigate(routes.getDeliveryDispensation, {
      state: { orderId: value },
    });
  };

  const searchSection = () => {
    return (
      <>
        <QRScannerComponent
          key={trigger}
          open={qrScanner.isOpen}
          onClose={() => {
            setQrScanner(state => ({
              ...state,
              isOpen: false,
            }));
            setTrigger(0);
          }}
          handleSubmit={handleSubmit}
          setQrScanner={setTrigger}
          trigger={trigger}
        />
        <div className="searchSection">
          <Box>
            <Title
              pt={"xl"}
              mt={"sm"}
              pb={"xs"}
              px={"lg"}
              size={"h3"}
              c="secondary-color"
              fw={"bold"}
              ta={"start"}
            >
              Buscar ordenamiento
            </Title>
            <Box px={"lg"}>
              <div className="cardSearch">
                <TextInput
                  label="Nombre o ID del paciente"
                  placeholder="Escribir..."
                  value={searchV}
                  onChange={({ target }) => {
                    setSearch(target.value);
                    setSearchV(target.value);
                    setInsuranceValue("");
                    setTypeOrder("patient");
                  }}
                />
                <Select
                  data-testid="insurance"
                  label="Aseguradora"
                  data={formattedDataSelect(insuranceList.results, "name", "id")}
                  searchable
                  value={String(insuranceValue)}
                  placeholder="Seleccionar..."
                  onChange={(_, value) => {
                    setSearch("");
                    setSearchV("");
                    setInsuranceValue(value.value);
                    setTypeOrder("corpClient");
                  }}
                />
              </div>
            </Box>
            {typeOrder !== "corpClient" && (
              <Box className="patientRow">
                <Box className="flex-column justify-center align-center ">
                  {patientList.results.length > 0 &&
                    patientList.results.map(patient => {
                      return (
                        <div key={patient.id}>
                          <div>
                            <hr />
                          </div>
                          <Box
                            px={"xs"}
                            key={patient.id}
                            className="pointer"
                            onClick={() => {
                              navigate(routes.getPatientOrderInformation, {
                                state: {
                                  data: patient,
                                },
                              });
                            }}
                          >
                            <Text fz={10} fw={"bold"}>
                              {patient.first_name}
                              &nbsp;
                              {patient.other_names}
                              &nbsp;
                              {patient.last_name}
                              &nbsp;
                              {patient.second_surname}
                            </Text>
                            <Text fz={10}>
                              {patient.docTypeDesc}
                              &nbsp;
                              {patient.document}
                            </Text>
                          </Box>
                        </div>
                      );
                    })}
                </Box>
              </Box>
            )}

            <Box className="flex-column justify-center align-center" mt={"xl"} px={"md"}>
              <Button
                size="sm"
                variant="outline"
                color="secondary-color.10"
                px="xl"
                onClick={() => {
                  setQrScanner(state => ({
                    ...state,
                    isOpen: true,
                  }));
                  setTrigger(trigger + 1);
                }}
              >
                Escanear código QR
              </Button>
            </Box>
          </Box>
          <Box className="flex items-end justify-center">
            <div className="h-100">
              <Image src={toOrder} />
            </div>
          </Box>
        </div>
      </>
    );
  };

  const renderComponent = () => {
    return (
      <Fragment>
        <div className="bodyOrder">
          {searchSection()}
          <div className="renderSection">
            {typeOrder === "corpClient" && (
              <InsuranceList type={typeOrder} corporateClient={insuranceValue} />
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  return renderComponent();
};

export default Order;

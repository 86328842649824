import * as MC from "@mantine/core";
import { Fragment } from "react/jsx-runtime";

import { useAuthentication } from "@app/store/useAuthtentication";

import HomeLink from "@app/common/components/HomeLink";

export default function Selection() {
  const allCategories = useAuthentication(state => state.auth.categories);

  const category = allCategories[0] || {};

  const renderComponent = () => {
    return (
      <Fragment>
        <MC.Title className="display-1" mb="md" c="brand-color">
          Módulo de Dispensación
        </MC.Title>

        {category.modules.map(link => (
          <HomeLink key={link.id} label={link.name} links={link.functionalities} />
        ))}

        <MC.Image src={category.categoryHomeImage} className="placeholder-image" w="40%" />
      </Fragment>
    );
  };

  return renderComponent();
}

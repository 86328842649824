import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app/app";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "sweetalert2/src/sweetalert2.scss";

import "./index.scss";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

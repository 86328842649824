/**
 * Parse a query string to an object.
 * @param {string} queryString Search params to be parsed.
 * @returns {Record<string, string>} Parsed search params into object.
 */
export function parseSearchParams<T>(queryString: string): T {
  const params = new URLSearchParams(queryString);
  const parsed: Record<string, string> = {};

  for (const [key, value] of params) {
    parsed[key] = value;
  }

  return parsed as T;
}

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useQuery } from "@tanstack/react-query";
import { supportConsultListService, supportDetailDispensaryService } from "./supportsConsult";
import { SupportConsultFilter } from "./types";

export function useGetSupportConsultList(
  filters: SupportConsultFilter & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: [
      "supportParams",
      filters.corporateClient,
      filters.contractId,
      filters.startDate,
      filters.endDate,
      filters.search,
      filters.status,
      filters.page,
    ],
    queryFn: async () => await supportConsultListService(filters, token),
  });

  return query;
}

export function useGetSupportDetailDispensary(filters: { id: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["supportParams", filters.id],
    queryFn: async () => await supportDetailDispensaryService(filters, token),
  });

  return query;
}

/**
 * Check if a variable has a value.
 * @param {any} variable Whatever value.
 * @returns {boolean} If the variable has a value or not.
 */
export const hasData = (variable: unknown): boolean => {
  let isValid = false;

  if (typeof variable === "boolean") {
    isValid = !!variable;
  }

  if (typeof variable === "string") {
    isValid = variable.trim() !== "";
  }

  if (typeof variable === "number") {
    isValid = variable > 0;
  }

  if (typeof variable === "undefined") {
    isValid = false;
  }

  if (typeof variable === "object") {
    isValid = Object.keys(variable || {}).length > 0;
  }

  if (variable === null) {
    isValid = false;
  }

  return isValid;
};

import { Center, Loader } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { hasData } from "@app/common/utils/hasData";
import { parseSearchParams } from "@app/common/utils/parseSearchParams";

import { useAuthentication } from "@app/store/useAuthtentication";

import type { IDataBiowel } from "@app/store/types";

const defaultDataSidebar = {
  id: 0,
  isExternal: 0,
  modules: [],
  name: "",
  categoryHomeImage: "",
  categoryImage: "",
};

export default function ValidateAuth() {
  const location = useLocation();
  const navigate = useNavigate();
  const { createAuthentication, clearAuthentication } = useAuthentication();

  const parsed = useMemo(() => {
    return parseSearchParams<IDataBiowel>(location.search);
  }, [location.search]);

  useEffect(() => {
    if (
      hasData(parsed.idAccount) &&
      hasData(parsed.idModule) &&
      hasData(parsed.idProfile) &&
      hasData(parsed.idUser) &&
      hasData(parsed.token)
    ) {
      const userData: IDataBiowel = {
        idAccount: +parsed.idAccount,
        idModule: +parsed.idModule,
        idProfile: +parsed.idProfile,
        idUser: +parsed.idUser,
        fullNameUser: parsed.fullNameUser,
        token: parsed.token,
        userImage: parsed.userImage,
      };
      createAuthentication({
        dataBiowel: userData,
        categories: [defaultDataSidebar],
      });
    } else {
      clearAuthentication();
    }
    navigate("/", { state: parsed.redirect });
  }, [createAuthentication, clearAuthentication, navigate, parsed]);

  return (
    <Center h="100vh">
      <Loader size="xl" />
    </Center>
  );
}

export function getEnvs() {
  const URL_PROTOCOL = import.meta.env.VITE_PUBLIC_URL_PROTOCOL;
  const URL_BASE = import.meta.env.VITE_PUBLIC_URL_BASE;
  const URL_GATEWAY = import.meta.env.VITE_PUBLIC_URL_GATEWAY;
  const API_VERSION = import.meta.env.VITE_PUBLIC_API_VERSION;

  return {
    URL_BASE,
    URL_GATEWAY,
    API_VERSION,
    URL_PROTOCOL,
  };
}

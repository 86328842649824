import { Center, Loader, MantineLoader, MantineSize } from "@mantine/core";

interface AppLoaderProps {
  type?: MantineLoader;
  size?: MantineSize;
}

export default function AppLoader(props: AppLoaderProps) {
  return (
    <Center className="app-loader">
      <Loader size={props.size || "xl"} type={props.type} />
    </Center>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import { successNotification, warningNotification } from "@app/common/components/notifications";
import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  handleAuthDelivery,
  listDeliveryAuthListService,
  medicationOrderService,
  requestDeliveryService,
} from "./deliveryAuth";
import { DeliveryAuthFilters, RequestFullDeliveryPayload } from "./types";

export function useGetDeliveryAuthList(
  filters: DeliveryAuthFilters & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: [
      "orders",
      filters.page,
      filters.startDate,
      filters.endDate,
      filters.staId,
      filters.search,
    ],
    queryFn: async () => await listDeliveryAuthListService(filters, token),
  });

  return query;
}
export function useGetMedicationOrder(filters: { id: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["orderpdf", filters.id],
    queryFn: async () => await medicationOrderService(filters, token),
  });

  return query;
}

export function useUpdateAuthDelivery(onSuccess = async () => {}) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: { rcdRejectReason?: string; approved: 0 | 1; id: number }) =>
      await handleAuthDelivery(payload, token),
    onSuccess: async data => {
      successNotification(data.message, "Actualizado exitosamente");
      await onSuccess();
    },
  });

  return mutation;
}

export function useRequestDeliveryFull(onSuccess = async () => {}) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: RequestFullDeliveryPayload) =>
      await requestDeliveryService(payload, token),
    onSuccess: async () => {
      successNotification("Solicitud de entrega completa creada.", "Realizado con éxito");
      await onSuccess();
    },
    onError: async (error: any) => {
      warningNotification(error.response.data.message, "Intenta de nuevo");
    },
  });

  return mutation;
}

import routes from "@app/config/routes";
import { Box, Button, Image, Select, TextInput, Title } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import toOrder from "../../../assets/toOrder.svg";
import "./styles.scss";

const Particular = () => {
  const navigate = useNavigate();
  const [registerPatient, setRegisterPatient] = useState<{ show: boolean }>({
    show: false,
  });

  const goToDispensation = () => {
    navigate(routes.getDeliveryDispensation);
  };

  const searchSection = () => {
    return (
      <>
        <div className="cardSearch">
          <TextInput
            label="Nombre o ID del paciente"
            placeholder="Escribir..."
            // className="secondary-color"
            // defaultValue={props.config.data?.description}
          />
        </div>
        <div
          className="flex gap-2 py-1"
          onClick={() => {
            setRegisterPatient(state => ({
              ...state,
              show: true,
            }));
          }}
        >
          <IconCirclePlus /> <span className="pl-1">Registrar nuevo paciente</span>
        </div>

        <Box className="flex-column justify-center align-center" mt={"xl"}>
          <Button
            size="sm"
            mt={"xs"}
            color="secondary-color.6"
            px="xl"
            // onClick={props.onClose}
          >
            Buscar
          </Button>
        </Box>
      </>
    );
  };

  const registerSection = () => {
    return (
      <>
        <div className="cardSearch">
          <TextInput
            label="Nombres"
            placeholder="Escribir..."
            // className="secondary-color"
            // defaultValue={props.config.data?.description}
          />
          <TextInput
            label="Apellidos"
            placeholder="Escribir..."
            // className="secondary-color"
            // defaultValue={props.config.data?.description}
          />
          <Select
            label="Tipo de identificación"
            // data={statusListSelect}
            placeholder="Seleccionar..."
            // onChange={(_, value) => {
            //     setEnabledValue(value.value);
            //     setCurrentPage(1);
            // }}
          />
          <TextInput
            label="No. identificación"
            placeholder="Escribir..."
            // className="secondary-color"
            // defaultValue={props.config.data?.description}
          />
          <TextInput
            label="Teléfono"
            placeholder="Escribir..."
            // className="secondary-color"
            // defaultValue={props.config.data?.description}
          />
          <TextInput
            label="Correo"
            placeholder="Escribir..."
            // className="secondary-color"
            // defaultValue={props.config.data?.description}
          />
        </div>

        <Box className="flex-column justify-center align-center" mt={"xl"}>
          <Button size="sm" mt={"xs"} color="secondary-color.6" px="xl" onClick={goToDispensation}>
            Registrar
          </Button>
        </Box>
      </>
    );
  };

  const renderComponent = () => {
    return (
      <Fragment>
        <div className="bodyOrder">
          <div className="searchSection">
            <Box>
              <Title mb="sm" size={"h3"} c="secondary-color" fw={"bold"}>
                Dispensación particular
              </Title>
              {registerPatient.show ? registerSection() : searchSection()}
            </Box>

            <Box className="flex items-end justify-center">
              <div className="h-100">
                <Image src={toOrder} />
              </div>
            </Box>
          </div>
          <div className="renderSection"></div>
        </div>
      </Fragment>
    );
  };

  return renderComponent();
};

export default Particular;

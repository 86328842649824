import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import {
  DetailSupportDispensaryResponse,
  SupportConsultFilter,
  SupportConsultResponse,
} from "./types";

export async function supportConsultListService(
  filters: SupportConsultFilter & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<SupportConsultResponse[]>>(
    "/dispensarySupports/",
    { params: filters },
  );
  return res.data;
}
export async function supportDetailDispensaryService(filters: { id: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<DetailSupportDispensaryResponse>>(
    `/dispensarySupports/detail/${filters.id}`,
    { params: filters },
  );
  return res.data;
}

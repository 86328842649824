import * as MC from "@mantine/core";
import * as TI from "@tabler/icons-react";

import { IAccount } from "@app/store/types";
import "./styles.scss";

interface IChangeAccountProps {
  isOpen: boolean;
  onClose: () => void;
  accounts?: IAccount[];
  onSelectAccount: (_accountId: number, _profielId: number) => void;
}

export default function ChangeAccount(props: IChangeAccountProps) {
  const onClose = () => {
    props.onClose();
  };

  const renderAccountItem = (account: IAccount) => {
    return (
      <MC.Menu radius="lg" position="right" width={210}>
        <MC.Menu.Target>
          <MC.Box className="account-item">
            <div></div>
            <MC.Text fw="bold">{account.name}</MC.Text>
            <TI.IconChevronRight className="x-large-icon muted-color" />
          </MC.Box>
        </MC.Menu.Target>

        <MC.Menu.Dropdown p={0} className="overflow-hidden">
          {account.profile.map(profile => (
            <MC.Menu.Item
              key={profile.id}
              py="xs"
              ta="center"
              fz="md"
              style={theme => ({
                borderBottom:
                  account.profile.at(-1)?.id !== profile.id
                    ? `1px solid ${theme.colors.gray[3]}`
                    : 0,
                borderRadius: 0,
              })}
              onClick={() => {
                props.onSelectAccount(account.id, profile.id);
                props.onClose();
              }}
            >
              {profile.nombre.toUpperCase()}
            </MC.Menu.Item>
          ))}
        </MC.Menu.Dropdown>
      </MC.Menu>
    );
  };

  const renderComponent = () => {
    return (
      <MC.Modal
        size={1200}
        title="Seleccionar cuenta"
        opened={props.isOpen}
        onClose={onClose}
        scrollAreaComponent={MC.ScrollArea.Autosize}
      >
        <MC.Grid px="lg">
          {props.accounts &&
            props.accounts.map(acc => (
              <MC.Grid.Col key={acc.id} span={6}>
                {renderAccountItem(acc)}
              </MC.Grid.Col>
            ))}
        </MC.Grid>
        <MC.Group px="xl" justify="flex-end">
          <MC.Button size="sm" color="secondary-color.6" px="xl" onClick={props.onClose}>
            Aceptar
          </MC.Button>
        </MC.Group>
      </MC.Modal>
    );
  };

  return renderComponent();
}

import DownloadTemplate from "@app/common/components/DownloadTemplate";
import { useGetMedicationOrder } from "@app/services/deliveryAuth/hooks";
import { useLocation, useNavigate } from "react-router-dom";

const DeliveryOrderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
    const parent = document.getElementById("mainContainer");
    if (parent) {
      parent.classList.remove("main-container");
      parent.classList.add("main-container");
    }
  };

  const {
    data: pdfOrder = {
      results: "",
    },
  } = useGetMedicationOrder({
    id: location.state.data,
  });

  return (
    <DownloadTemplate
      goBackButton={() => {
        navigate(-1);

        goBack();
      }}
      url={pdfOrder.results.replace(/^b'/gm, "").replace(/=*'$/gm, "") as string}
      urlType="base64"
      title="Detalle de fórmula de medicamentos"
    />
  );
};

export default DeliveryOrderDetail;

import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useQuery } from "@tanstack/react-query";
import { listOrdersService, lotsScanner, orderDetail } from "./drugDelivery";
import { OrderListFilters } from "./types";

export function useGetOrdersList(filters: OrderListFilters & IFiltersPagination, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: [
      "orders",
      filters.page,
      filters.startDate,
      filters.endDate,
      filters.site,
      filters.doctor,
      filters.corporateClient,
    ],
    queryFn: async () => await listOrdersService(filters, token),
  });

  return query;
}
export function useGetOrderDetail(filters: { orderId: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["ordersDetail", filters.orderId],
    queryFn: async () => await orderDetail(filters, token),
  });

  return query;
}
export function useGetLotsScanner(filters: { orderId: number; barcode: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["lotsScanner", filters.barcode],
    queryFn: async () => await lotsScanner(filters, token),
  });

  return query;
}

import routes from "@app/config/routes";
import Features from "@app/features";
import DeliveryAuth from "@app/features/deliveryAuth/DeliveryAuth";
import DeliveryAuthDetail from "@app/features/deliveryAuth/DeliveryAuthDetail";
import DetailDispensarySupportFiles from "@app/features/dispensarySupportFiles/DetailDispensarySupportFiles";
import DispensarySupportFiles from "@app/features/dispensarySupportFiles/DispensarySupportFiles";
import Delivery from "@app/features/drugDelivery/delivery/Delivery";
import DeliveryOrderDetail from "@app/features/drugDelivery/delivery/DeliveryOrderDetail";
import Order from "@app/features/drugDelivery/order/Order";
import PatientOrderInformation from "@app/features/drugDelivery/order/patient/PatientOrderInformation";
import Particular from "@app/features/drugDelivery/particular/Particular";
import SelectionDelivery from "@app/features/drugDelivery/selection/Selection";
import Selection from "@app/features/menu/Selection";
import DetailSupportsConsults from "@app/features/supportsConsult/DetailSupportsConsults";
import SupportsConsult from "@app/features/supportsConsult/SupportsConsults";
import HandleUpdateSupportParameter from "@app/features/supportsParameters/HandleUpdateSupportParameter";
import SupportsParameters from "@app/features/supportsParameters/SupportsParameters";
import { createBrowserRouter } from "react-router-dom";
import Guardian from "./guardian";
import ValidateAuth from "./validate";

const router = createBrowserRouter([
  { path: routes.root, element: <Guardian /> },
  { path: routes.validate, element: <ValidateAuth /> },
  {
    path: routes.dispensation,
    element: <Features />,
    children: [
      {
        index: true,
        element: <Selection />,
      },
      {
        path: routes.drugDelivery,
        element: <SelectionDelivery />,
      },
      {
        path: routes.getEpsOrder,
        element: <Order />,
      },
      {
        path: routes.particularRoute,
        element: <Particular />,
      },
      {
        path: routes.getDeliveryDispensation,
        element: <Delivery />,
      },
      {
        path: routes.getPatientOrderInformation,
        element: <PatientOrderInformation />,
      },
      {
        path: routes.getDeliveryAuth,
        element: <DeliveryAuth />,
      },
      {
        path: routes.getDeliveryAuthDetail,
        element: <DeliveryAuthDetail />,
      },
      {
        path: routes.getDeliveryDetailOrder,
        element: <DeliveryOrderDetail />,
      },
      {
        path: routes.getSupportsParameter,
        element: <SupportsParameters />,
      },
      {
        path: routes.getCreateSupportParameter,
        element: <HandleUpdateSupportParameter />,
      },
      {
        path: routes.getEditSupportParameter,
        element: <HandleUpdateSupportParameter />,
      },
      {
        path: routes.getSupportsConsult,
        element: <SupportsConsult />,
      },
      {
        path: routes.getSupportsConsultDetail,
        element: <DetailSupportsConsults />,
      },
      {
        path: routes.getDispensarySupportFiles,
        element: <DispensarySupportFiles />,
      },
      {
        path: routes.getDispensarySupportFilesDetail,
        element: <DetailDispensarySupportFiles />,
      },
    ],
  },
]);

export default router;

import Swal from "sweetalert2";

export function successNotification(message: string, title: string) {
  Swal.fire({
    title: `<span class="primary-color">${title}</span>`,
    text: message,
    icon: "success",
    confirmButtonText: "Aceptar",
    customClass: {
      confirmButton: "swal-confirm-button",
    },
  });
}

export function warningNotification(message = "Ha ocurrido un error", title = "Intenta de nuevo") {
  Swal.fire({
    title: `<span class="primary-color">${title}</span>`,
    text: message,
    icon: "warning",
    confirmButtonText: "Aceptar",
    customClass: {
      confirmButton: "swal-confirm-button",
    },
  });
}

export function confirmNotification(
  message: string,
  title = "¿Está seguro?",
  onConfirm: () => Promise<void> | void | undefined,
) {
  Swal.fire({
    title: `<span class="primary-color">${title}</span>`,
    text: message,
    icon: "warning",
    confirmButtonText: "Sí, continuar",
    cancelButtonText: "Cancelar",
    showCancelButton: true,
    customClass: {
      confirmButton: "swal-confirm-button",
      cancelButton: "swal-cancel-button",
    },
    showLoaderOnConfirm: true,
  }).then(async answer => {
    if (answer.isConfirmed) {
      await onConfirm?.();
    }
  });
}

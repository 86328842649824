import dayjs from "dayjs";
import { useMemo } from "react";

/**
 * Calculate the pages for a table depending on the row total.
 * @param {number} total
 * @returns {number} Calculated pages for the total
 */
export const calculatePagination = (total: number) => {
  return Math.ceil(Math.ceil(total) / 10);
};

export const statusListSelect = [
  {
    label: "Habilitado",
    value: "enabled",
  },
  { label: "Deshabilitado", value: "disabled" },
];

/**
 * Transform a number into a currency value.
 * @param {number} number
 * @returns {string} Formatted number to currency
 */
export function formatNumberToCop(number: number): string {
  const str = number.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return str;
}

export function chunkPagination<T>(array: T[], size: number): T[][] {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);
  return [head, ...chunkPagination(tail, size)];
}

export const containsNumbers = (text: string) => /\d/.test(text);

export const validateEmail = (value: string) => {
  const valid = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  const response = valid.test(value) ? true : false;
  return response;
};

export const fileToBase64 = (file: File | null): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve("");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(",").at(-1) || "");
    reader.onerror = error => reject(error);
  });
};

export const formattedDataSelect = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  label: string,
  value: string | number,
  defaultOption: boolean = true,
) => {
  const toMap = data.map(item => ({
    value: String(item[value]),
    label: String(item[label]),
  }));

  if (defaultOption) {
    return [{ label: "Seleccionar...", value: "" }, ...toMap];
  } else {
    return [...toMap];
  }
};

export const UseFormattedDataSelect = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  label: string,
  value: string | number,
  defaultOption: boolean = true,
) => {
  return useMemo(() => {
    const toMap = data.map(item => ({
      value: String(item[value]),
      label: String(item[label]),
    }));

    if (defaultOption) {
      return [{ label: "Seleccionar...", value: "" }, ...toMap];
    } else {
      return [...toMap];
    }
  }, [data, label, value, defaultOption]); // Solo recalculará si estos valores cambian
};

export const today = () => new Date().toISOString().split("T")[0];

export const formatTimeToAmPm = (time: string): string => {
  if (time) {
    return dayjs("1/1/1 " + time).format("hh:mm a");
  } else return "-";
};

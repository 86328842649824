import { useQuery } from "@tanstack/react-query";

import { HTTP_BASE } from "@app/config/axios";
import { useAuthentication } from "@app/store/useAuthtentication";

import type { IResponse } from "@app/common/types/http";
import type { IAccount } from "@app/store/types";

const fetchAccounts = async (idUser: number, token: string) => {
  const response = await HTTP_BASE(token).get<{ accounts: IAccount[] } & IResponse>(
    `/composite/selectaccount/${idUser}`,
  );
  return response.data;
};

export const useGetAccounts = (idUser: number) => {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["accounts"],
    queryFn: async () => await fetchAccounts(idUser, token),
  });

  return query;
};

import * as MC from "@mantine/core";
import * as TI from "@tabler/icons-react";
import clsx from "clsx";
import { useState } from "react";

import { useAuthentication } from "@app/store/useAuthtentication";

import SidebarLink from "./SidebarLink";

import "./styles.scss";

export default function Sidebar() {
  const allCategories = useAuthentication(state => state.auth.categories);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const category = allCategories[0] || {};

  const renderComponent = () => {
    return (
      <aside className={clsx("app-sidebar", { closed: !isSidebarOpen })}>
        <>
          {isSidebarOpen ? (
            <MC.ActionIcon
              variant="subtle"
              size="lg"
              my="xs"
              mr={-8}
              style={{ alignSelf: "flex-end" }}
              onClick={() => setIsSidebarOpen(false)}
            >
              <TI.IconX
                style={{
                  width: 25,
                  height: 25,
                  strokeWidth: 2,
                }}
              />
            </MC.ActionIcon>
          ) : (
            <MC.Center w={80}>
              <MC.ActionIcon
                variant="outline"
                size="lg"
                radius="sm"
                my="xs"
                style={{ borderWidth: 2 }}
                onClick={() => setIsSidebarOpen(true)}
              >
                <TI.IconMenu2 style={{ strokeWidth: 3 }} />
              </MC.ActionIcon>
            </MC.Center>
          )}
          {category.modules?.map(link => (
            <SidebarLink
              key={link.id}
              icon={link.img}
              label={link.name}
              links={link.functionalities}
              onClick={() => (!isSidebarOpen ? setIsSidebarOpen(true) : null)}
              isSidebarClosed={!isSidebarOpen}
              onCloseSidebar={() => setIsSidebarOpen(false)}
            />
          ))}
        </>
      </aside>
    );
  };

  return renderComponent();
}

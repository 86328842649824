import dayjs from "dayjs";

export const generateId = () => {
  return Date.now().toString(36) + Math.random().toString(36).slice(2);
};

export function calculateAge(birthDate: string): number {
  const today = dayjs();
  const birth = dayjs(birthDate);
  return today.diff(birth, "year");
}

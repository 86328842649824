import { MantineProvider, localStorageColorSchemeManager } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import "dayjs/locale/es";
import { RouterProvider } from "react-router-dom";

import router from "./router";

import { warningNotification } from "@app/common/components/notifications";
import { IHttpStatusCodes, type IResponse } from "@app/common/types/http";
import { getEnvs } from "@app/common/utils/getEnvs";
import theme, { themeResolver } from "@app/config/theme";

const colorSchemeManager = localStorageColorSchemeManager({
  key: "color-scheme",
});

const envs = getEnvs();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      const axiosError = error as AxiosError<IResponse>;
      if (axiosError.response?.status === IHttpStatusCodes.Unauthorized) {
        warningNotification(axiosError.response?.data.message);
        window.location.href = `${envs.URL_PROTOCOL}${envs.URL_BASE}/login`;
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 0,
      enabled: true,
    },
    mutations: {
      onError(error) {
        const axiosError = error as AxiosError<IResponse>;
        warningNotification(axiosError.response?.data.message);
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={theme}
        defaultColorScheme="light"
        colorSchemeManager={colorSchemeManager}
        cssVariablesResolver={themeResolver}
      >
        <DatesProvider settings={{ locale: "es" }}>
          <RouterProvider router={router} />
        </DatesProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}

export default App;

import { Box, Button, Modal } from "@mantine/core";
import { IconCameraRotate, IconCapture, IconChecklist } from "@tabler/icons-react";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

import "./styles.scss";

export interface BodyCenterList {
  articleBarCode: string;
  articleId: number;
  articleName: string;
  cssId: number;
  expirationDate: string;
  files: File[];
  lotId: number;
  lotName: string;
  quantity: number;
  evidence: number;
  qx: string;
  justification: string;
  expectedAmount: number;
}

export interface File {
  id?: number;
  name?: string;
  url?: string | null;
  base64?: string | null;
  typeDoc?: string;
  fileName?: string;
  idTemp?: string;
}

export default function DeliveryRegisterPicture({
  isOpen,
  onClose,
  setData,
}: {
  data?: string;
  isOpen: boolean;
  setData: (data: string) => void;
  onClose: () => void;
}) {
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  const addEvidence = async () => {
    if (imgSrc) {
      setData(imgSrc.replace(/^data:image\/[a-z]+;base64,/, ""));
    }
    onClose();
    setImgSrc(null);
  };

  return (
    <Modal
      w="45%"
      size={500}
      title="Tomar foto"
      opened={isOpen}
      onClose={() => {
        onClose();
        setImgSrc(null);
      }}
    >
      <Box className="flex flex-column justify-center">
        <div className="container flex flex-column justify-center items-center">
          {imgSrc ? (
            <Box my={"sm"}>
              <img src={imgSrc} alt="webcam" />
            </Box>
          ) : (
            <div className="d-flex justify">
              <Webcam height={225} width={300} ref={webcamRef} screenshotFormat="image/png" />
            </div>
          )}
          <Box mt={"sm"}>
            {imgSrc ? (
              <Box className="flex justify-center ">
                <Button variant="outline-danger" mr={"md"} onClick={retake} data-testid="retake">
                  <IconCameraRotate />
                </Button>
                <Button variant="outline" onClick={addEvidence} data-testid="save">
                  <IconChecklist />
                </Button>
              </Box>
            ) : (
              <Button variant="outline" onClick={capture} data-testid="capture">
                <IconCapture />
              </Button>
            )}
          </Box>
        </div>
      </Box>
    </Modal>
  );
}

import { useAuthentication } from "@app/store/useAuthtentication";

export function GCidAccount() {
  const idAccount = useAuthentication(store => store.auth.dataBiowel.idAccount);
  return idAccount;
}

export function GCToken() {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  return token;
}

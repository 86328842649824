import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import {
  LotsScannerResponse,
  OrderListFilters,
  OrderListPatient,
  OrderListResponse,
} from "./types";

export async function listOrdersService(
  filters: OrderListFilters & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<OrderListPatient[]>>("/ordering/", {
    params: filters,
  });
  return res.data;
}

export async function orderDetail(filters: { orderId: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<OrderListResponse>>("/dispensaries/", {
    params: filters,
  });
  return res.data;
}

export async function lotsScanner(filters: { orderId: number; barcode: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<LotsScannerResponse>>("/lots/", {
    params: filters,
  });
  return res.data;
}
